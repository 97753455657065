import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useOnClickOutside } from "../../hooks";
import styled from "@emotion/styled";
import tw from "twin.macro";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Burger from "./Burger";
import OffCanvas from "../OffCanvas/OffCanvas";
import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";
import Accordion from "../Navigation/Accordion";

const StyledMainNav = styled.nav`
   ${({ headerHasBorder }) => (headerHasBorder ? tw`border-b border-solid border-white border-opacity-25` : "")};
   /* @media (min-width: 1024px) {
        ${({ megaMenuHovering }) => (megaMenuHovering ? tw`bg-white` : null)};
    } */
   @media (max-width: 1023px) {
      ${({ offcanvasOpen }) => (offcanvasOpen ? tw`bg-secondary` : "")};
      /* ${({ headerStyle }) => (headerStyle === "overlap" ? tw`bg-white` : null)};  */
   }
   &[data-fixed="true"] {
      ${tw`fixed top-0 left-0 w-full bg-white z-20`}
      @media (max-width: 1023px) {
         ${({ offcanvasOpen }) => (offcanvasOpen ? tw`bg-secondary` : "")};
      }
      #navigation-desktop {
         > li {
            > a {
               ${tw`text-gray-800`}
            }
         }
      }
      .logo-initial {
         ${tw`hidden`}
      }
      .logo-fixed {
         ${tw`block`}
      }
   }
   #navigation-desktop {
      > li {
         &:not(:last-child) {
            ${tw`lg:mr-8`}
         }
         > a {
            ${({ headerLinkColor }) => (headerLinkColor === "white" ? tw`text-white` : tw`text-gray-900`)};
            /* ${({ megaMenuHovering }) => (megaMenuHovering ? tw`text-gray-800` : null)}; */
            ${tw`relative font-body font-medium no-underline pb-8`}
            /* &:after {
               content: "";
               ${tw`absolute bottom-0 right-0 left-0 mx-auto h-1 bg-primary w-0 transform -translate-y-6 transition-all duration-300 ease-linear`}
            } */
            &:hover {
               ${tw`text-primary`}/* &:after {
                  ${tw`w-8`}
               } */
            }
         }
         &.active {
            ${tw`text-primary`}/* &:after {
               ${tw`w-8`}
            } */
         }
      }
      .is-submenu-parent {
         ${tw`relative`}
         .submenu {
            ${tw`absolute flex flex-col w-auto bg-white shadow-3xl pt-6 px-6 pb-5 opacity-0 invisible z-10 transform -translate-x-10 translate-y-12 transition-all duration-300 ease-linear`}
            li {
               ${tw`whitespace-nowrap`}
               &:not(:last-child) {
                  ${tw`mb-4`}
               }
               a {
                  ${tw`relative font-body text-gray-400 no-underline`}
                  /* &:after {
                            content: '';
                            ${tw`absolute left-auto right-0 bottom-0 h-px bg-primary w-0 transition-all duration-300 ease-linear`}
                        } */
                        &:hover {
                     ${tw`text-primary`}/* &:after {
                                ${tw`w-full right-auto left-0`}
                            } */
                  }
               }
            }
         }
         &:hover {
            .submenu {
               ${tw`opacity-100 visible translate-y-7`}
            }
         }
      }
      /* .is-mega-menu-parent {
            ${tw`static`}
            .mega-menu {
                ${tw`absolute left-0 flex flex-col w-full bg-white shadow-3xl pt-20 pb-12 opacity-0 invisible z-10 transform translate-y-12 transition-all duration-300 ease-linear`}
            }
            &:hover {
                .mega-menu {
                    ${tw`opacity-100 visible translate-y-7`}
                }
            }
        }         */
   }

   #navigation-mobile {
      ${tw`w-full text-center`}
      > li {
         ${tw`relative`}
         &:not(:last-child) {
            ${tw`mb-7`}
         }
         > a,
         button {
            ${tw`font-heading text-mobile-4xl text-white font-bold no-underline`}
            &:hover {
               ${tw`text-primary`}
            }
            &:focus {
               ${tw`outline-none`}
            }
         }
         &.is-submenu-parent {
            > a {
               ${tw`block`}
               /* &:after {
                        content: '\f078';
                        font-family: 'Font Awesome 5 Pro';
                        ${tw`absolute top-0 right-0 font-normal text-black transition-all duration-300 ease-linear`}
                    } */
                    &.active {
                  ${tw`text-primary`}/* &:after {
                            content: '\f068';
                        } */
               }
            }
         }
      }
      .submenu {
         ${tw`flex-col transition-all duration-300 ease-linear`}
         li {
            &:not(:last-child) {
               ${tw`mb-4`}
            }
            a {
               ${tw`text-white`}
               &:hover {
                  ${tw`text-primary`}
               }
            }
         }
      }
   }
`;

const MainNav = ({ scrolled, headerStyle, headerLinkColor, headerHasBorder }) => {
   // determine if offcanvas is open
   const [offcanvasOpen, setOffcanvasOpen] = useState(false);

   // Hover on parent links
   // const [megaMenuHovering, setMegaMenuHovering] = useState(false);
   // const isHoveringMegaMenu = () => setMegaMenuHovering(true);
   // const notHoveringMegaMenu = () => setMegaMenuHovering(false);

   const [subMenuHovering1, setSubMenuHovering1] = useState(false);
   const isHoveringSubMenu1 = () => setSubMenuHovering1(true);
   const notHoveringSubMenu1 = () => setSubMenuHovering1(false);

   // const [subMenuHovering2, setSubMenuHovering2] = useState(false);
   // const isHoveringSubMenu2 = () => setSubMenuHovering2(true);
   // const notHoveringSubMenu2 = () => setSubMenuHovering2(false);

   // handle click of navigation items
   const clickHandler = () => {
      setOffcanvasOpen(!offcanvasOpen);
   };

   // close offcanvas onclick outside
   const node = useRef();
   useOnClickOutside(node, () => setOffcanvasOpen(false));

   const data = useStaticQuery(graphql`
      {
         logoDark: file(relativePath: { eq: "global/logo-dark.svg" }) {
            publicURL
         }
         logoLight: file(relativePath: { eq: "global/logo-light.svg" }) {
            publicURL
         }
      }
   `);

   // Define logos based on header style
   let initialLogo = null,
      stickyLogo = null,
      className = null;

   if (headerStyle === "overlap") {
      initialLogo = data.logoLight.publicURL;
      stickyLogo = data.logoDark.publicURL;
      className = "absolute";
   } else {
      initialLogo = data.logoDark.publicURL;
      stickyLogo = data.logoDark.publicURL;
   }

   if (offcanvasOpen) {
      initialLogo = data.logoLight.publicURL;
      stickyLogo = data.logoLight.publicURL;
   }

   return (
      <StyledMainNav
         id="main-navigation"
         className={`py-3 w-full transition duration-300 ease-linear ${className}`}
         role="navigation"
         aria-label="main-navigation"
         data-fixed={scrolled}
         headerStyle={headerStyle}
         headerHasBorder={headerHasBorder}
         headerLinkColor={headerLinkColor}
         // megaMenuHovering={megaMenuHovering}
         offcanvasOpen={offcanvasOpen}
      >
         <div className="container flex justify-between items-center">
            {/* <div className="lg:hidden flex-auto flex items-center">
               <div className="logo-initial">
                  <a href="tel:+">
                     <i class="fas fa-phone-alt"></i>
                  </a>
               </div>
               <div className="logo-fixed hidden">
                  <a href="tel:+">
                     <i class="fas fa-phone-alt"></i>
                  </a>
               </div>
            </div> */}
            <div className="flex-auto flex items-center">
               <AniLink fade to="/">
                  <div className="logo-initial">
                     <img src={initialLogo} alt="Smart Accounting Hub Logo" width="150" className="w-[87px] md:w-[150px]" />
                  </div>
                  <div className="logo-fixed hidden">
                     <img src={stickyLogo} alt="Smart Accounting Hub Logo" width="150" className="w-[87px] md:w-[150px]" />
                  </div>
               </AniLink>
            </div>
            <div className="flex items-center justify-end flex-auto">
               <ul id="navigation-desktop" className="hidden lg:flex lg:items-center lg:justify-end lg:mr-10">
                  <li className={`is-submenu-parent ${subMenuHovering1 ? "active" : ""}`}>
                     <AniLink fade to="/services/" onMouseEnter={isHoveringSubMenu1} onMouseLeave={notHoveringSubMenu1}>
                        Services
                     </AniLink>
                     <ul className="submenu">
                        <li>
                           <AniLink fde to="/personal-bookkeeping-services/">
                              Personal Bookkeeping
                           </AniLink>
                        </li>
                        <li>
                           <AniLink fde to="/business-bookkeeping-services/">
                              Business Bookkeeping
                           </AniLink>
                        </li>
                        <li>
                           <AniLink fde to="/small-business-management-services/">
                              Business Management
                           </AniLink>
                        </li>
                        <li>
                           <AniLink fde to="/financial-reporting-services/">
                              Financial Reporting
                           </AniLink>
                        </li>
                     </ul>
                  </li>
                  <li>
                     <AniLink fde to="/about/">
                        About
                     </AniLink>
                  </li>
               </ul>
               <div className="hidden lg:inline-flex items-center">
                  <ButtonGhost href="tel:+1-619-292-8205" text={[<i className="fas fa-phone-alt mr-2"></i>, "(619) 292-8205"]} className="mr-4" />
                  <ButtonSolid as="button" modal="modal-contact" text="Contact" />
               </div>

               <div className="lg:hidden" ref={node}>
                  <Burger
                     offcanvasOpen={offcanvasOpen}
                     setOffcanvasOpen={setOffcanvasOpen}
                     headerStyle={headerStyle}
                     scrolled={scrolled}
                     aria-controls="offcanvas-navigation"
                  />
                  <OffCanvas offcanvasOpen={offcanvasOpen} id="offcanvas-navigation">
                     <ul id="navigation-mobile" className="mb-48">
                        <li>
                           <Accordion title="Services">
                              <li>
                                 <AniLink fade to="/services/" onKeyDown={clickHandler} onClick={clickHandler}>
                                    All Services
                                 </AniLink>
                              </li>
                              <li>
                                 <AniLink fade to="/personal-bookkeeping-services/" onKeyDown={clickHandler} onClick={clickHandler}>
                                    Personal Bookkeeping
                                 </AniLink>
                              </li>
                              <li>
                                 <AniLink fade to="/business-bookkeeping-services/" onKeyDown={clickHandler} onClick={clickHandler}>
                                    Business Bookkeeping
                                 </AniLink>
                              </li>
                              <li>
                                 <AniLink fade to="/small-business-management-services/" onKeyDown={clickHandler} onClick={clickHandler}>
                                    Business Management
                                 </AniLink>
                              </li>
                              <li>
                                 <AniLink fade to="/financial-reporting-services/" onKeyDown={clickHandler} onClick={clickHandler}>
                                    Financial Reporting
                                 </AniLink>
                              </li>
                           </Accordion>
                        </li>
                        <li>
                           <AniLink fade to="/about/" onKeyDown={clickHandler} onClick={clickHandler}>
                              About
                           </AniLink>
                        </li>
                     </ul>

                     <div className="grid grid-cols-1 gap-y-6">
                        <ButtonGhost href="tel:+1-619-292-8205" text="(619) 292-8205" darkMode={true} />
                        <ButtonSolid as="button" modal="modal-contact" text="Get a Quote" />
                     </div>
                  </OffCanvas>
               </div>
            </div>
         </div>
      </StyledMainNav>
   );
};
export default MainNav;
