import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import ModalContact from "../Modal/ModalContact";
// import Newsletter from '../Form/Newsletter'

const Footer = () => {
   const getYear = () => {
      return new Date().getFullYear();
   };

   const data = useStaticQuery(graphql`
      {
         logo: file(relativePath: { eq: "global/logo-dark.svg" }) {
            publicURL
         }
      }
   `);

   return (
      <div>
         <footer className="bg-white pt-16 md:pt-20 pb-6 md:pb-10 text-center md:text-left">
            <div className="container">
               <div className="md:flex items-end md:justify-between md:mb-10">
                  <div className="mb-12 md:mb-0">
                     <img src={data.logo.publicURL} alt="Smart Accounting Hub logo" width="150" className="mx-auto md:mx-0" />
                  </div>
                  <div className="mb-12 md:mb-0">
                     <ul className="flex flex-col md:flex-row items-center justify-center md:justify-end">
                        <li className="mb-4 md:mb-0 md:mr-8">
                           <AniLink fade to="/services/" className="text-black font-medium">
                              Services
                           </AniLink>
                        </li>
                        <li className="mb-4 md:mb-0 md:mr-8">
                           <AniLink fade to="/about/" className="text-black font-medium">
                              About
                           </AniLink>
                        </li>
                        <li>
                           <button data-modal-open="modal-contact" className="text-black font-medium focus:outline-none">
                              Contact
                           </button>
                        </li>
                     </ul>
                  </div>
               </div>

               <div className="flex flex-wrap md:flex-nowrap flex-col md:flex-row justify-items-center md:justify-between items-center md:border-t border-solid border-black border-opacity-20 md:pt-3">
                  <div className="md:flex md:items-center order-2 md:order-1">
                     <ul className="flex items-center flex-col md:flex-row">
                        <li className="text-sm text-black text-opacity-70 mb-2 md:mb-0">
                           © {getYear()} Smart Accounting Hub All Rights Reserved
                           <span className="hidden md:inline mx-1">|</span>
                        </li>
                        <li className="text-sm mb-2 md:mb-0">
                           <AniLink fade to="/privacy-policy/" className="text-black text-opacity-70 hover:text-opacity-100">
                              Privacy Policy
                           </AniLink>
                           <span className="hidden md:inline mx-1">|</span>
                        </li>
                        <li className="text-sm text-opacity-70">
                           <div className="flex items-center justify-center">
                              Powered by
                              <a
                                 className="font-medium ml-1"
                                 href="https://www.wisedigitalpartners.com/affordable-web-design/"
                                 target="_blank"
                                 rel="noopener noreferrer"
                              >
                                 NEST <sup className="relative text-[0.375rem] left-[0.125rem] -top-1">TM</sup>
                              </a>
                           </div>
                        </li>
                     </ul>
                  </div>

                  <div className="flex justify-center md:justify-items-start order-1 md:order-2 mb-10 md:mb-0">
                     <a
                        href="https://www.facebook.com/pages/category/Business-Service/Smart-Accounting-Hub-112331496974771/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className=""
                     >
                        <i className="fab fa-facebook-square text-2xl text-primary"></i>
                     </a>
                  </div>
               </div>
            </div>
         </footer>

         <ModalContact />
      </div>
   );
};

export default Footer;
