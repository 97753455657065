import React from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

const StyledButton = styled.a`
   /* ${({ darkMode }) => darkMode && tw``}; */
`;

const Button = ({ className, href, target, rel, modal, onClick, as, type, darkMode, text }) => (
   <StyledButton
      className={`font-body font-medium text-white hover:text-white bg-primary hover:bg-primary_light rounded-md px-3 py-2.5 h-[48px] min-w-[160px] inline-flex items-center justify-center text-center no-underline focus:outline-none transition-colors duration-300 ease-in-out ${
         darkMode ? "" : ""
      } ${className}`}
      href={href}
      target={target}
      rel={rel}
      data-modal-open={modal}
      onClick={onClick}
      as={as}
      type={type}
      darkMode={darkMode}
   >
      {text}
   </StyledButton>
);

export default Button;
